<template>
    <ion-page>
        <ion-card>
            <ion-card-header class="ion-text-center">
                <img src="assets/logo.png" alt="Dealer Incident Reporting" />
                <ion-card-title>Authenticating. . .</ion-card-title>
            </ion-card-header>

            <ion-card-content>
                <ion-spinner v-if="initializing" color="primary"></ion-spinner>
            </ion-card-content>
        </ion-card>
    </ion-page>
</template>

<script>
import { IonPage, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonSpinner } from '@ionic/vue';
import Api from '@/services/Api';

export default {
    name: 'Init',
    components: { IonPage, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonSpinner },
    data(){
        return {
            initializing: true,
        };
    },
    async mounted(){
        if(!this.$store.state.token){
            await this.$router.push({ name: 'login' });
        } else {
            const response = await Api.verify();
            this.initializing = false;
            if(response.success){
                this.$store.commit('authenticate', { token: this.$store.state.token, user: response.user });
                await this.$router.push({ name: 'dashboard' });
            } else {
                this.$store.commit('unauthenticate');
                await this.$router.push({ name: 'login' });
            }
        }
    }
};
</script>
