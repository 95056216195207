import { createStore } from 'vuex';
import axios from 'axios';

const store = createStore({
    state(){
        return {
            authenticated: JSON.parse(localStorage.getItem('authenticated')) || false,
            token: localStorage.getItem('token') || null,
            user: JSON.parse((localStorage.getItem('user'))) || null,
        };
    },
    mutations: {
        authenticate(state, payload){
            state.token = payload.token;
            state.user = payload.user;
            localStorage.setItem('authenticated', JSON.stringify(true));
            localStorage.setItem('token', payload.token);
            localStorage.setItem('user', JSON.stringify(payload.user));
            axios.defaults.headers.common['Authorization'] = `Bearer ${payload.token}`;
            state.authenticated = true;
        },
        unauthenticate(state){
            state.token = null;
            state.user = null;
            localStorage.removeItem('authenticated');
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            delete axios.defaults.headers.common['Authorization'];
            state.authenticated = false;
        },
    },
});

export default store;
