import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import store from '@/store';
import Init from '@/views/Init.vue';
import Tabs from '@/views/Tabs.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'init',
        component: Init,
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
        meta: { guest: true },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/Register.vue'),
        meta: { guest: true },
    },
    {
        path: '/incident/:id',
        name: 'view-incident',
        component: () => import('@/views/ViewIncident.vue'),
        meta: { auth: true },
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('@/views/Logout.vue'),
        meta: { auth: true },
    },
    {
        path: '/tabs/',
        component: Tabs,
        children: [
            {
                path: '',
                redirect: '/tabs/dashboard',
            },
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('@/views/Dashboard.vue'),
            },
            {
                path: 'incidents',
                name: 'incidents',
                component: () => import('@/views/Incidents.vue'),
            },
            {
                path: 'report',
                name: 'report',
                component: () => import('@/views/ReportIncident.vue'),
            },
        ],
        meta: { auth: true },
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
        if (!store.state.authenticated) {
            next({ name: 'init', params: { nextUrl: to.fullPath }})
        } else {
            if (to.matched.some(record => record.meta.admin)) {
                if (store.state.user.is_admin) {
                    next()
                } else {
                    next({ name: 'dashboard' })
                }
            } else {
                next()
            }
        }
    } else if (to.matched.some(record => record.meta.guest)) {
        if (store.state.authenticated) {
            next({ name: 'logout' })
        } else {
            next()
        }
    } else {
        next()
    }
});

export default router;
