<template>
    <ion-page>
        <ion-tabs>
            <ion-router-outlet></ion-router-outlet>
            <ion-tab-bar slot="bottom">
                <ion-tab-button tab="dashboard" href="/tabs/dashboard">
                    <ion-icon :icon="homeOutline"/>
                    <ion-label>Dashboard</ion-label>
                </ion-tab-button>

                <ion-tab-button tab="incidents" href="/tabs/incidents">
                    <ion-icon :icon="listCircle"/>
                    <ion-label>Incidents</ion-label>
                </ion-tab-button>

                <ion-tab-button tab="report" href="/tabs/report">
                    <ion-icon :icon="addCircle"/>
                    <ion-label>Report</ion-label>
                </ion-tab-button>
            </ion-tab-bar>
        </ion-tabs>
    </ion-page>
</template>

<script lang="ts">
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { homeOutline, listCircle, addCircle } from 'ionicons/icons';

export default {
    name: 'Tabs',
    components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
    setup(){
        return {
            homeOutline,
            listCircle,
            addCircle,
        };
    },
};
</script>
